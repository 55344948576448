@import '../../style/colors';
@import '../../style/mixins.scss';
.mainHeader {
  padding-left: var(--space-header);
  padding-right: var(--space-header);
  z-index: 1300 !important;
  background-color: var(--background-color) !important;
  position: sticky !important;

  &.removeBorder {
    background-color: var(--background-color) !important;
    .onlyCover {
      box-shadow: none;
    }
  }

  .onlyCover {
    background-color: var(--background-color);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    @include respond(tab-port) {
      &.shorter {
        height: 60px !important;
      }
    }
  }

  &.fixed {
    top: 0;
    left: auto;
    right: 0;
    position: sticky;
  }

  &.appBarSlide {
    .onlyCover {
      transform: translateY(0);
    }

    .middle {
      .searchBar {
        opacity: 1;
        display: flex;
      }
    }
  }

  &.appBarHidden {
    transition: 200ms ease-in-out;
    background-color: transparent;

    .searchBar {
      display: flex;
    }
  }
}

.viewAll {
  position: sticky;
  width: 100%;
  height: 50px;
  bottom: 0;
  display: flex;
  background-color: var(--background-color);

  a {
    width: 100%;
    text-decoration: none;
  }
}

.header {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0 !important;
  transition: 100ms ease-in-out;

  &.shorter {
    height: 50px;
  }

  @include respond(tab-port) {
    justify-content: space-between;
    padding-top: 20px !important;
  }
}

.left {
  display: flex;
  align-items: center;
  flex: 0 1 auto;

  a {
    display: flex;
    align-items: center;
  }

  .beta {
    color: red;
    font-size: 16px;
    font-weight: 600;
    line-height: 8px;
  }
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  @include respond(tab-port) {
    flex: 100%;
    order: 1;
  }
  .searchBar {
    width: 100%;
    opacity: 0;
    display: none;
    @include respond(tab-port) {
      &.hidden {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-80%);
        transition: 0s ease;
      }
    }
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;

  .buttons {
    gap: 5px;

    button {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-right: 0 !important;
      width: 100px;

      &.login {
        background-color: #f1f1f1;
        color: #323232;
        font-weight: 700;
      }

      &.explore {
        border-color: var(--primary-color);
        color: var(--primary-color);
        font-weight: 700;
      }

      &.navigation {
        padding: 10px;
        border-radius: 10px;

        margin-right: 10px;

        &.active {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }

    @include respond(tab-port) {
      display: none;
    }
  }

  // menuIcon and closeIcon button
  .menuButton {
    width: 20px;
    z-index: 10;
    display: none;
    &:before,
    &:after,
    & div {
      background: rgb(175, 174, 174);
      content: '';
      display: block;
      height: 2px;
      border-radius: 5px;
      margin: 4px 0;
      transition: 0.5s;
    }
    &.close:before {
      transform: translateY(7px) rotate(135deg);
    }
    &.close:after {
      transform: translateY(-5px) rotate(-135deg);
    }
    &.close div {
      transform: scale(0);
    }

    @include respond(tab-port) {
      display: block;
    }
  }
}

.drawer {
  background: var(--background-color);
  padding: 0;
  margin-top: 70px;

  .drawerLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;

    h2 {
      font-weight: normal;
      font-size: 32px;
      color: $white;
      margin: 16px;
      margin-bottom: 32px;
    }

    .buttons {
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      align-items: center;

      button {
        width: 80%;

        &.login {
          margin: 0;
        }

        &.explore {
          margin: 0;
        }
      }
    }
  }

  .drawerMenuItems {
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: var(--space-header) var(--space-header);
    }
  }
}

.buttons {
  display: flex;
}

button {
  padding: 10px 20px;
  line-height: 23px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--primary-text-color);

  &.login {
    background-color: #f1f1f1;
    color: #323232;
  }

  &.explore {
    border-color: var(--primary-color);
    background-color: #f0ecfb;
    color: var(--primary-color);
  }

  &.pause {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &.navigation {
    padding: 10px;
    border-radius: 10px;
    color: var(--primary-color);
    background-color: var(--background-color);
    border-color: var(--primary-color);
    &:first-child {
      margin-right: 10px;
    }
    &.create {
      margin-right: 0px;
    }
    &.active {
      border-color: white;
      background-color: var(--primary-color);
      color: white;
    }
  }
}

.logo {
  height: 25px;
  cursor: pointer;
  display: flex;
  height: 100%;

  img {
    height: 25px;
    scale: 1.3;
  }
}

.upSize {
  img {
    height: 50px;
    scale: 1;
    @include respond(tab-port) {
      height: 25px;
    }
  }
}

.transparent {
  background-color: transparent !important;
}

.searchcont {
  margin-left: 30px;
  margin-right: 30px;
  flex: 1;
  height: 50px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  background: rgba(255, 255, 255, 0.16);

  ::placeholder {
    color: $white;
    opacity: 0.8;
  }
}

.searchicon {
  width: 22px;
  height: 22px;
  padding-right: 5.5px;
  color: $white;
}

.closeicon {
  cursor: pointer;
}

.searchinput {
  width: 250px;
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: $white;
}

.searchinput::placeholder {
  color: $white;
  opacity: 1;
}

.resultcont {
  box-shadow: rgb(100 100 100 / 50%) 0px 4px 16px;
  padding: 0 0;
  background-color: rgb(255, 255, 255);
  color: rgb(4, 17, 29);
  overflow-y: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.resultsection {
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
}

.resultsectiontitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 16px;
  color: rgb(112, 122, 131);
}

.separator {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.result {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: 16px;
  transition: all 0.2s ease 0s;
  color: rgb(4, 17, 29);

  &:hover {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    background-color: rgb(251, 253, 255);
  }
}

.resultimg {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.resulttitle {
  flex-grow: 1;
  width: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px;
  font-style: italic;
}

.menu {
  display: flex;
  align-items: center;

  .searchcont {
    display: none;
  }
}

.secondmenu {
  display: none;
  align-items: center;
  margin-left: auto;
}

.drawerHeader {
  display: flex;
  justify-content: flex-end;

  .drawerLogo {
    display: flex;
    align-items: center;
    margin-left: 10px;

    img {
      height: 25px;
      filter: invert(100%);
    }
  }
}

.drawerCloseIcon {
  margin: 10px;
}

.account {
  display: flex;
  gap: 1px;
  align-items: center;
  cursor: pointer;

  svg {
    color: #dcdcdc;
  }

  @include respond(tab-port) {
    display: none;
  }
}

.mainMenu {
  padding: 10px;
}

.avatarSmall {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  overflow: hidden;
}

.avatarBig {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  overflow: hidden;
}

.profile {
  margin-right: 4px;
}

.address {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.network {
  margin-top: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.6;
}

.expand {
  transition: transform ease 200ms;

  &.expanded {
    transform: rotate(-180deg);
  }
}

.hasBorder {
  border-bottom: 1px solid var(--border-color);
}

.modeContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  .active {
    color: var(--primary-color) !important;
    fill: var(--primary-color) !important;
    stroke: var(--primary-color) !important;
  }
}

.mobileAction {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: 100%;
  }
}
