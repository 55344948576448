@import '../../style/colors';
@import '../../style/mixins.scss';

.socialMediaInput {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  .closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .hideCloseBtn {
    opacity: 0;
  }
  @include respond(tab-port) {
    flex-direction: column;
    .hideCloseBtn {
      display: none;
    }
  }
}

.checkBox {
  margin: 0px;
  a {
    text-decoration: none;
    color: var(--primary-color);
  }
}
.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  .formLabel {
    display: flex;
    align-items: center;
    color: var(--primary-text-color);
    margin-bottom: 5px;
    font-size: 16px;
    svg {
      width: 1rem;
      height: 1rem;
      color: var(--primary-text-color);
    }
  }
  .select {
    width: 100%;
    outline: none !important;
    height: 50px !important;
    border-radius: 10px !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    padding-left: 12px !important;
    cursor: pointer !important;
    background-color: var(--place-holder-color);
    border: 1px var(--light-border-color) solid;
    color: var(--primary-text-color) !important;
    .menuLabel {
      margin: 0px !important;
    }

    svg {
      color: var(--primary-text-color) !important;
    }
  }
}

.inputURL {
  flex: 1;
}

.button {
  width: 170px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  background-color: var(--primary-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background-color: $primary;
  color: $white;
  margin-right: 40px;
  text-decoration: none;
}

.cancel {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}

.title {
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 110%;
  color: var(--primary-text-color);
}

.subtitleContainer {
  text-align: center;
}

.subtitle {
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  opacity: 0.5;
  margin-top: 15px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.walletAddress {
  color: $primary;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.btnGroup {
  display: flex;
  width: 100%;
  gap: 10px;
  button {
    flex: 1;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
}

.addBtn {
  margin-top: 10px;
  @include respond(tab-port) {
    display: none;
  }
}
