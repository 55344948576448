@import '../../style/colors';

.formGroup {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.inline {
  display: flex;
  align-items: center;
  gap: 10px;

  .formLabel {
    margin-bottom: 0;
  }

  .formInputCont {
    flex: 1;
    input {
      width: 100%;
    }
  }
}

.groupRoot {
  cursor: default !important;
}

.groupTitle {
  cursor: default;
}

.stripeModal {
  max-height: 450px;
  overflow: auto;
  margin: 0 -20px -10px;
}

.paypalModal {
  max-height: 450px;
  overflow: auto;
  margin: 10px -20px;
  padding: 15px;
  transition: height 0.3s ease-in-out;
}

.formLabel {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: var(--primary-text-color);
  margin-bottom: 11px;
  margin-left: 0;
  display: flex;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .selections {
    display: flex;
    gap: 10px;

    button {
      padding: 10px 20px;
      min-width: 80px;
      border-radius: 10px;
      color: var(--primary-color);
      font-weight: bold;
      &.active {
        background-color: var(--primary-color);
        color: var(--white-color);
      }
    }
  }

  .sellerAccount {
    display: flex;

    button {
      border: none;
      padding: 0;
      margin-left: 5px;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-color);

  &.focused {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

.formGroupDates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.formInput {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: var(--primary-text-color);
}

.usdPrice {
  min-width: 130px;
  height: 50px;
  border-left: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: var(--primary-text-color);
}

.select {
  margin: 2px;
  width: 140px !important;
  outline: none !important;
  height: 44px !important;
  border-radius: 8px !important;
  border: none !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  padding-right: 12px !important;
  background-color: var(--secondary-background-color);
  box-shadow: none !important;
}

.selectedToken,
.token {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
}

.token {
  width: 138px;
  padding: 5px 15px;

  &:hover {
    background-color: #eaeaf1;
  }
}

.tokenIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.tokenSymbol {
  margin-left: 8px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: var(--primary-text-color);
}

@media only screen and (max-width: 600px) {
  .formGroupDates {
    display: block;
  }
  .usdPrice {
    min-width: 100px;
    font-size: 14px;
  }
  .formInput {
    min-width: 50px;
    padding: 8px 8px;
    font-size: 14px;
  }
  .selectedToken {
    padding: 5px 5px;
  }
  .tokenSymbol {
    font-size: 14px;
  }
}
