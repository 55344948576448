.datagridContainer {
  border-radius: 10px;
  border: 1px solid var(--border-color);
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 10px;
    color: var(--primary-text-color);
    div {
      font-weight: 700;
      font-size: 16px;

      &.userCount {
        border-radius: 10px;
        padding: 0 8px;
        font-weight: 600;
        font-size: 14px;
        background-color: var(--secondary-background-color);
        color: var(--primary-color);
      }
    }
  }
}

:global {
  .rdg {
    border: none !important;
    & > div {
      background-color: var(--background-color) !important;
      border-block-end: none;
      border-inline-end: none;
    }
    .rdg-header-row {
      border-right: none !important;

      .rdg-cell {
        font-size: 14px;
        font-weight: 500;
      }

      span {
        color: var(--primary-text-color) !important;
      }
    }
    .rdg-cell {
      border-right: none !important;
      border-color: var(--border-color) !important;
      color: var(--primary-text-color) !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
