@import '../../style/colors';

$scaleFactor: 1.5;
$authAnimationLength: 0.5s;

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px var(--space-sides);
  .inputGroup {
    box-sizing: border-box !important;
    width: 100% !important;
    padding: 20px !important;
    background-color: var(--background-color);
    font-size: 16px !important;

    .inputGroupTitle {
      font-weight: 400;
      font-size: 28px !important;
      line-height: 120%;
      color: #121223;
      margin: 0;
      padding: 20px 0;
      border-bottom: 1px #eaecf0 solid;
    }
  }

  ul {
    a {
      text-decoration: none;
      color: var(--primary-text-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
.outerContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.documentLink {
  margin: 5px;
}

.licenseGridItem {
  text-align: center;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.registryLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-text-color);
  text-align: center;
}

.registryValue {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding-bottom: 10px;
  margin-top: 10px;
  opacity: 1;
}

.documentIcon {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
.authContainer {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  backdrop-filter: blur(17px);
  .inner {
    box-sizing: border-box;
    background-color: var(--background-color);
    height: 100%;
    width: 100%;
    flex: 1;
    .header {
      height: 300px;
      position: relative;
      width: 100%;
      overflow: hidden;
      .background {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--secondary-background-color);
        .bg1 {
          position: absolute;
          left: -10%;

          img {
            height: 400px;
            transform: rotate(108deg);
          }
        }
        .bg2 {
          position: absolute;
          right: -5%;

          img {
            height: 400px;
            transform: rotate(242deg);
          }
        }
      }
      .headerContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;
        .time {
          font-size: 16px;
          color: $primary;
        }
        .title {
          font-size: 48px;
          color: var(--primary-text-color);
        }
        .description {
          width: 50%;
          text-align: center;
          font-size: 20px;
          color: #b6b6b6;
        }
      }
    }
    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p,
      ul {
        color: var(--secondary-text-color);
        font-size: 16px;
      }
    }
    .download {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 10px var(--space-sides) 0;
    }
    .authForm {
      padding: 10px var(--space-sides) var(--space-sides);
      position: relative;
      width: 100%;
      animation: slideFromBottom $authAnimationLength forwards;
      -webkit-animation: slideFromBottom $authAnimationLength forwards;
      h2 {
        color: var(--primary-text-color);
      }

      @keyframes slideFromBottom {
        from {
          transform: translate(0, 250px) scale(0.9, $scaleFactor);

          opacity: 0;
        }

        to {
          transform: translate(0, 0) scale(1, 1);

          opacity: 1;
        }
      }

      .closeButton {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 12px;
        height: 12px;
        opacity: 0.4;
        cursor: pointer;
      }
      .title {
        font-size: 60px;
        font-weight: 600;
        line-height: 110%;
        color: $secondary;
        text-align: center;
        padding-bottom: 48px;

        animation: slide $authAnimationLength forwards;
        -webkit-animation: slide $authAnimationLength forwards;

        @keyframes slide {
          from {
            transform: translate(0, 60px) scale(1, 0.666 /*1/$scaleFactor*/);

            opacity: 0;
          }

          to {
            transform: translate(0, 24px) scale(1, 1);

            opacity: 1;
          }
        }
      }
      .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #121223;
        opacity: 0.4;
      }

      .codeInputContainer {
        display: flex;
        justify-content: center;
      }
      .codeInput {
        text-align: center;
        margin: 6px;
        input {
          text-align: center;
          margin: 16px;
          font-size: 18px;
          height: 56px;
          width: 48px;
        }
        input:nth-child(3) {
          margin-right: 24px;
        }
      }

      .inputGroup {
        margin-top: 16px;
        padding-left: 12px;
        padding-right: 12px;
        .inputWrapper {
          margin-top: 16px;
          text-align: center;
          .input {
            width: 400px;
            height: 60px;
            padding: 8px 16px;
            box-sizing: border-box;
            font-size: 20px;
            color: $secondary;
            border-radius: 12px;
            border: none;
            outline: none;
            border: 0;
            background-color: var(--background-color);
            input {
              text-align: center !important;
              background-color: var(--secondary-background-color);
              color: var(--primary-text-color);
              height: 21px;
              &:-webkit-autofill {
                box-shadow: 0 0 0 30px $grey-100 inset !important;
                -webkit-box-shadow: 0 0 0 30px $grey-100 inset !important;
                font-size: inherit;
                -webkit-text-fill-color: inherit !important;
              }
            }

            &.password input {
              padding-left: 48px;
            }

            fieldset {
              border: 0;
              border-radius: 12px;
            }
            &.hasError {
              border: 1px solid $red;
            }
          }
        }
      }
      .error {
        color: $red;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
      .formLabelRow {
        padding-top: 16px;
        padding-bottom: 15px;
        display: flex;
        gap: 16px;
        line-height: 32px;
      }
      .formLabelRowCenterredGray {
        padding-top: 12px;
        color: rgba(18, 18, 35, 0.4);
        text-align: center;
        align-items: center;
        align-content: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
      }
      .subtitle {
        padding-top: 32px;
        font-size: 24px;
        font-weight: 400;
        color: rgba(18, 18, 35, 0.4);
        text-align: center;
        line-height: 32px;
      }
      .indented {
        padding-left: 80px;
        padding-right: 80px;
      }
      .forgotPasswordLink {
        color: var(--primary-text-color);
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.2px;
        font-weight: 600;
        cursor: pointer;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
      }
      .labelLink {
        color: var(--primary-color);
        font-size: 16px;
        // line-height: 32px;
        letter-spacing: -0.2px;
        font-weight: 700;
        cursor: pointer;
      }
      .label {
        color: var(--secondary-text-color);
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: normal;
      }
      .cancelLink {
        margin-top: 16px;
      }
      .buttonsWrapper {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .submitButton {
        padding-left: 32px;
        padding-right: 32px;
        height: 55px;
        border-radius: 12px;
        background-color: var(--primary-color);
        color: var(--primary-text-color);
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        letter-spacing: -0.2px;
        align-items: center;
        justify-content: center;

        &:hover {
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        }
      }
      .confirmIcon {
        text-align: center;
      }
    }
  }
}

.formGroup {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.formLabel {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: var(--secondary-text-color);
  margin-bottom: 11px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eaeaf1;

  &.focused {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

.formInput {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.termsOfServiceLink {
  text-decoration: none;
}

@media only screen and (max-width: 960px) {
  .authContainer {
    .inner {
      .authForm {
        width: 100%;
        max-width: 450px;
        text-align: center;
        height: 100%;
        padding: 8px;
        padding-bottom: 30px;
        background-color: var(--background-color);
        .title {
          margin-top: 30px;
          font-size: 30px;
          font-weight: 600;
        }
        .subTitle {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
        }
        .codeInput {
          margin: 0;
          input {
            text-align: center;
            margin: 6px;
            font-size: 16px;
            height: 44px;
            width: 34px;
          }
          input:nth-child(3) {
            margin-right: 16px;
          }
          input:nth-child(1) {
            margin-left: 0px;
          }
          input:nth-child(6) {
            margin-right: 0px;
          }
        }
        .inputGroup {
          .inputWrapper {
            .input {
              width: 100%;
            }
          }
        }
        .formLabelRowCenterredGray {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .header {
    height: auto !important;
    padding: 20px 0px;
    .headerContent {
      .title {
        font-size: 24px !important;
      }
      .description {
        font-size: 16px !important;
      }
      .title,
      .description,
      .time {
        width: 100% !important;
        text-align: center;
      }
    }
  }
}
