@import '../../style/colors';

.container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1300;
  align-items: center;
  justify-content: center;
  &.visible {
    display: flex;
  }
}
.backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1300;
}
.modal {
  max-width: 90%;
  width: 600px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  background-color: var(--background-color);
  color: var(--primary-text-color);
  position: relative;
  z-index: 1400;
}

.autoWidth {
  width: auto;
}
.buySkeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite linear;
  border-radius: 8px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.higherLayer {
  z-index: 1401;
}
.small {
  width: 400px;
}

.header {
  height: 70px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
}

.headerWithoutTitle {
  flex-direction: row-reverse;
  border: none;
  height: 0px;
}

.body {
  padding: 24px;
}

.bodyWithoutTitle {
  padding: 0 24px 10px 24px;
}

.title {
  font-weight: 400;
  font-size: 24px;
  color: var(--primary-text-color);
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    color: var(--primary-text-color);
  }
}

.footer {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.submitButton {
  min-width: 168px;
  height: 48px;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.submitButton {
  background-color: $primary;
  color: $white;
}

.submitDenyButton {
  background-color: $danger;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .listButton {
    width: calc(100% - 48px);
  }

  .listButton {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .body {
    padding: 10px;
  }
}
