@import 'style/colors';

$bgDefaultWidth: 500px;
$bgDefaultHeight: 500px;

.container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--background-color);
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $grey-100;
}

.banner {
  width: 100%;
  height: 100%;
  // position: absolute;
  max-height: 410px;
  margin-bottom: -130px;
  position: relative;
}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerPlaceholder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url('../../assets/imgs/user_banner.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.sumsubContainer {
  width: 100%;
  &.hidden {
    display: none;
  }
}

.bannerButtons {
  position: absolute;
  bottom: 32px;
  right: var(--space-header);
  display: flex;
  gap: 10px;
  z-index: 10;

  .btn {
    cursor: pointer;
    width: 42px;
    height: 42px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 5;
    backdrop-filter: blur(8px);

    &:hover {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      background-color: var(--secondary-background-color);
    }

    img {
      filter: invert(100%);
    }

    svg {
      width: 1rem;
      height: 1rem;

      path {
        color: $grey-100;
      }
    }
  }
}

.buttonsWrapper {
  display: flex;
  z-index: 5;
  justify-content: flex-end;
}

.wrapper {
  background-color: white;
  min-height: 410px;
  z-index: 6;
}
.wrapperRow {
  display: flex;
  flex-direction: column;
  margin-top: -135px;
  margin-left: var(--space-header);
  margin-right: var(--space-header);
  justify-content: flex-end;

  & > div:not(.avatarWrapper) {
    display: grid;
    grid-template-columns: 1fr fit-content(420px);
    gap: 15px;
  }
}

.bio {
  margin: 5px var(--space-header) 15px;
}

.avatarWrapper {
  min-width: 160px;
  min-height: 160px;
  max-width: 160px;
  max-height: 160px;
  border-radius: 50%;
  z-index: 6;
  overflow: hidden;
  margin-bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 4px solid white;
  box-shadow: 0px 0px 6px 0px #989898;

  .avatarChange {
    position: absolute;
    background-color: #00000075;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: 500;
    font-size: 14px;
    pointer-events: none;
    opacity: 0;
    transition: 200ms ease;
  }
}

.avatar {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    & + .avatarChange {
      opacity: 1;
    }
  }
}

.defaultAvatar {
  width: 160px;
  height: 160px;

  img {
    width: 160px;
    height: 160px;
  }
}

.usernameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .nameUser {
    font-size: 30px;
    font-weight: 600;
    color: var(--primary-text-color);
    line-height: 39px;
    white-space: nowrap;
    text-wrap: wrap;
    display: grid;
    align-items: center;
    gap: 5px;
    grid-template-columns: 1fr fit-content(50px);

    .nameSection {
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }

    .icon {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .followersSection {
    display: flex;
    gap: 10px;
    font-weight: 400;
    color: $third;

    .followers {
      cursor: pointer;
      line-height: 20px;
      display: flex;
      gap: 5px;

      &:hover {
        border-bottom-color: rgb(180, 180, 180);
      }
    }
  }
}

.followersModal {
  width: 200px;
}

.icon {
  width: 30px;
  height: 30px;
  color: red;
}

.followBtn {
  margin-left: 16px;
  width: 80px;
  height: 26px;
  border-radius: 4px;
  background-color: $primary;
  color: $white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.bottomWrapperInner {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.contentSidebar {
  width: 288px;
  flex: 0 0 288px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondary;
  padding-top: 5px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  min-height: 200px;
  height: fit-content;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.addressWrapper {
  width: fit-content;
  display: flex;
  gap: 10px;

  .buttonsSection {
    height: 45px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    .btn {
      margin-right: 5px;
      cursor: pointer;
      padding: 7px;
      display: flex;
      align-items: center;

      svg {
        height: 23px;
        color: var(--primary-text-color);
      }
    }

    .vertical {
      border-right: 1px solid lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60%;
      margin-right: 15px;
    }
  }

  button {
    padding: 10px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 10px;
    color: var(--primary-text-color);
    max-height: 45px;
    min-width: 140px;
    &.tokens {
      font-weight: 700;
    }
    &.referrals {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
    svg {
      scale: 0.8;
      fill: var(--primary-text-color);
    }
  }
  .unFollow {
    background-color: var(--white-color);
    color: var(--primary-color);
    min-width: 70px;
  }

  .follow {
    background-color: var(--primary-color);
    color: var(--white-color);
    min-width: 70px;
  }
}

.title {
  font-weight: 600;
  font-style: normal;
  font-size: 24px !important;
  color: var(--primary-text-color) !important;
}

.description {
  font-size: 18px !important;
  color: $third !important;
}

.tooltip {
  font-size: 5px;
}

.blackListed {
  padding: 10px;
  text-align: center;
  font-size: 20px;
  width: 100%;
  margin-bottom: 250px;
}

.blackListedContact {
  color: var(--primary-color);
  padding: 0px 5px;
}

.content {
  box-sizing: border-box;
  display: flex;
  z-index: 5;
  margin: 5px var(--space-header) 0;
}

.contentBody {
  flex-grow: 1;
  height: fit-content;
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: wrap;
  padding: 0 0 0 16px;
  margin-bottom: 100px;

  &.noPadding {
    padding: 0 !important;
  }

  .exploreAll {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.walletVerificationContainer,
.kycAuthentificationContainer,
.twoFAContainer {
  text-align: center;
  width: 100%;
  .walletVerificationHeader,
  .kycAuthentificationTitle,
  .twoFAHeader {
    margin-bottom: 2px;
  }
  .submainSubtitle,
  .walletVerificationSubtitle,
  .kycAuthentificationSubtitle,
  .twoFASubtitle {
    padding: 10px 0 10px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .label2FAContainer {
    display: flex;
    justify-content: center;

    .label2FA {
      max-width: 820px;
    }
  }
  .walletVerificationLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    color: var(--primary-text-color);
    padding-top: 5px;
  }
  .walletAddress {
    padding-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: var(--primary-text-color);
    text-align: center;
  }
  .tabButtonsWrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .veifyButton {
    padding-top: 30px;
  }
  .submitButton {
    padding: 18px 32px;
    border-radius: 12px;
    background-color: $primary !important;
    color: $white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    letter-spacing: -0.2px;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  .cancelButton {
    padding: 18px 32px;
    border-radius: 12px;
    background-color: #f4f5f8;
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    letter-spacing: -0.2px;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  .walletVerificationOkIcon,
  .kycAuthentificationOkIcon,
  .twoFAOkIcon {
    width: 72px;
    height: 72px;
  }
}

.connectWallet {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  color: var(--primary-text-color);
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .walletAddress {
      font-size: 16px;
    }
  }
}
.instructionSteps {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  .instructionContainer {
    max-width: 350px;
    height: 300px;
    background: #f4f5f8;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;

    .instructionHeader {
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 110%;
      margin: 4px;
    }

    .instructionDescription {
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      margin: 4px;
    }
  }
}

.activity {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .name,
  .owner {
    color: $primary;
    text-decoration: none;
  }
}

.activity + *:not(.ownerAvatarWrapper) {
  white-space: nowrap;
  padding-right: 16px;
}

.activityList {
  width: fit-content;
  min-width: 100%;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.menuItem {
  height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;

  img {
    width: 20px;
    margin-right: 16px;
  }

  svg {
    scale: 0.85;
  }
}

.formInputCont {
  width: 267px;
  outline: none;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 26px;
}

.qrcodeImg {
  width: 118px;
  height: 118px;
  margin: 16px;
}

.singleItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  .csvReport {
    align-self: end;
    width: 175px;
    height: 33px;
    background: var(--primary-color);
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    margin-right: 20px;
    cursor: pointer;
  }
}

.iconMenu {
  display: none !important;
}

.inputGroup {
  margin-top: 16px;
  width: 50%;
  display: flex;
  flex-direction: column;
  button {
    align-self: flex-end;
  }
}

.accordionContainer {
  width: 100%;
  background-color: var(--background-color) !important;
  border-radius: 8px !important;
}

.error {
  color: $red;
  font-size: 14px;
  line-height: 24px;
}

@media only screen and (max-width: 1024px) {
  .iconMenu {
    display: flex !important;
  }

  .tab {
    padding: 0 12px;
    margin: 0;
    justify-content: center;
  }

  .wrapperRow {
    margin-top: -95px;
    & > div {
      grid-template-columns: 115px 1fr;
      margin-bottom: 3px;
      gap: 15px;
    }
    .buttonsWrapper {
      display: none;
    }
  }

  .avatarWrapper {
    min-width: 115px;
    min-height: 115px;
    max-width: 115px;
    max-height: 115px;
    margin-bottom: -10px;

    .avatar {
      width: 110px;
      height: 110px;
    }
  }

  .bottomWrapperInner {
    .usernameWrapper {
      margin-bottom: 10px;

      .nameUser {
        font-size: 25px;
        line-height: 25px;
      }
      .followersSection {
        font-size: 14px;
      }
    }
  }

  .buttonsWrapper {
    .addressWrapper {
      button {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }

  .content {
    max-height: calc(100vh - 133px);
    flex-direction: column;
    max-height: none;
    margin-top: 0;
  }

  .bio {
    margin: 5px var(--space-header) 5px;
  }

  .contentSidebar {
    width: 240px;
    flex: 0 0 240px;
  }

  .contentBody {
    flex-direction: column;
    padding-left: 0 !important;
    margin-top: 15px;
  }

  .followersContainer {
    display: flex;
    padding: 16px;
  }

  .avatarContainer {
    display: flex;
    justify-content: center;
  }

  .contentSidebar {
    display: none;
  }

  .menuMobile {
    width: 100%;
    display: block;
  }

  .accordion {
    background-color: #f4f5f8 !important;
  }

  .accordionContent {
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  .groupTitle {
    font-size: 20px;
    font-weight: 700;
    margin: 8px 0;
  }

  .bg2,
  .bg3 {
    display: none;
  }

  .bannerPlaceholder {
    .bg4 {
      top: 50% !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .iconMenu {
    display: flex !important;
  }
  .wrapper {
    min-height: 330px;
    .banner {
      max-height: 320px;
      margin-bottom: -80px;
    }
  }
  .inputGroup {
    width: 100%;
  }
  .wrapperRow {
    // height: 122%;
  }
  .usernameWrapper {
    margin-bottom: 5px !important;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    min-height: 270px;
    .banner {
      margin-bottom: -45px;
    }
  }
  .wrapperRow {
    // height: 127%;
  }
  .usernameWrapper {
    margin-bottom: 5px !important;
  }
}

.currentTab {
  font-size: 18px;
  font-weight: 600;
  background-color: #f4f5f8;
  padding: 12px;
  border-radius: 12px;
  width: fit-content;
  margin-bottom: 8px;
}

.makeOffer {
  width: 120px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  font-size: 14px;
  font-weight: 500;
  color: $white;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  }
}

.optionPanel {
  margin-left: 0 !important;
  padding-top: 12px;
  border-bottom: 1px solid #eaeaf1;
  align-items: flex-start;

  &:last-child {
    border-bottom: 0;
  }
}

.option {
  margin-left: 5px;
}

.optionTitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

.optionDesc {
  font-size: 12px;
  line-height: 140%;
  font-weight: 400;
  color: #a2a2ad;
  margin-top: 7px;
}

.formContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .inputAnnoucement {
    font-size: 24px;
  }
}

.input {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 0;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f4f5f8;
  fieldset {
    border-color: transparent !important;
  }
  margin: 10px 0;
  &::placeholder {
    opacity: 0.66;
  }
}

.createButton {
  height: 48px;
  border-radius: 8px;
  background-color: $primary !important;
  color: $white;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.errorValidate {
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

.securityContainer {
  width: 100%;
  text-align: center;
}

.scroll {
  position: absolute;
  bottom: 30%;
}

@media screen and (max-width: 1700px) {
  .scroll {
    position: absolute;
    bottom: 13%;
  }
}
